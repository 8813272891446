/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

module.exports = {
  mobile: {
    xs: 0,
  },
  tablet: {
    sm: "576px",
    md: "768px",
  },
  desktop: {
    mdlg: '769px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
};
